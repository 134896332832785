import React, { useState } from "react"
import Layout from "../components/layout"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"
import SEO from "../components/seo"
import Button from "@bit/azheng.joshua-tree.button"

function allCorrect(obj) {
  for (var o in obj) if (obj[o] !== "correct") return false

  return true
}
function allFilledIn(obj) {
  for (var o in obj) if (obj[o] === "") return false

  return true
}

const WisdomStepTwoPage = () => {
  let language = "en"

  const [correct, setCorrect] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const [values, setValues] = useState({
    bestTime: "",
    xRays: "",
    aka: ""
  })

  const [staticValues, setStaticValues] = useState()

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setStaticValues(values)
    if (!allFilledIn(values)) {
      setErrorMessage("*Please fill out all questions before submitting.")
    } else {
      setSubmitted(true)
      if (allCorrect(values)) {
        setErrorMessage("")
        setCorrect(true)
      } else {
        setErrorMessage("*Oops, looks like one of the answers was incorrect.")
      }
    }
  }

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          lang={language}
          title="Take the Wisdom For Wisdom quiz"
          description="Follow the Wisdom For Wisdom steps to apply"
        />
        <div className="joshua-tree-content program-page-wisdom">
          <div className="columns top-section white-back has-text-centered quiz-page">
            <div className="column">
              <h1>Step 2: Take the Quiz</h1>
            </div>
          </div>

          <section className="body-section">
            <div className="columns">
              <div className="column is-4"></div>
              <div className="column">
                <div className="w4w__quiz">
                  <h3
                    id="ageTitle"
                    className={
                      submitted && staticValues.bestTime !== "correct"
                        ? "wrong"
                        : ""
                    }
                  >
                    When is the best time to have wisdom teeth removed?
                  </h3>
                  <div className="input-wrapper">
                    <input
                      type="radio"
                      onChange={handleChange("bestTime")}
                      id="child"
                      name="age"
                      defaultValue="child"
                    />
                    <label htmlFor="child">As a young child</label>
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="radio"
                      onChange={handleChange("bestTime")}
                      id="teen"
                      name="age"
                      defaultValue="correct"
                    />
                    <label htmlFor="teen">During the teenage years</label>
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="radio"
                      onChange={handleChange("bestTime")}
                      id="adult"
                      name="age"
                      defaultValue="adult"
                    />
                    <label htmlFor="adult">As an adult</label>
                  </div>
                  <h3
                    id="xrayTitle"
                    className={
                      submitted && staticValues.xRays !== "correct"
                        ? "wrong"
                        : ""
                    }
                  >
                    X-rays can be used to determine the presence of wisdom
                    teeth.
                  </h3>
                  <div className="input-wrapper">
                    <input
                      type="radio"
                      onChange={handleChange("xRays")}
                      id="xtrue"
                      name="xrays"
                      defaultValue="correct"
                    />
                    <label htmlFor="xtrue">True</label>
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="radio"
                      onChange={handleChange("xRays")}
                      id="xfalse"
                      name="xrays"
                      defaultValue="false"
                    />
                    <label htmlFor="xfalse">False</label>
                  </div>
                  <h3
                    id="molTitle"
                    className={
                      submitted && staticValues.aka !== "correct" ? "wrong" : ""
                    }
                  >
                    What are wisdom teeth also known as?
                  </h3>
                  <div className="input-wrapper">
                    <input
                      type="radio"
                      onChange={handleChange("aka")}
                      id="2mol"
                      name="molars"
                      defaultValue="2mol"
                    />
                    <label htmlFor="2mol">Second Molars</label>
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="radio"
                      onChange={handleChange("aka")}
                      id="3mol"
                      name="molars"
                      defaultValue="correct"
                    />
                    <label htmlFor="3mol">Third Molars</label>
                  </div>
                  <div className="input-wrapper">
                    <input
                      type="radio"
                      onChange={handleChange("aka")}
                      id="4mol"
                      name="molars"
                      defaultValue="4mol"
                    />
                    <label htmlFor="4mol">Fourth Molars</label>
                  </div>
                  <div style={{ width: "100%", textAlign: "center" }}>
                    {!correct && (
                      <div className="error-box" style={{ color: "#990000" }}>
                        {errorMessage}
                      </div>
                    )}
                    <div id="answer-box">
                      {correct && <h3>Yay!!! You're a wisdom tooth genius!</h3>}
                    </div>
                    {!correct && (
                      <a
                        className="standard-button submitQuiz contained"
                        onClick={handleSubmit}
                      >
                        Submit
                      </a>
                    )}
                    {correct && (
                      <Button
                        className="wisdom-button contained"
                        buttonText="Step 3: Application"
                        href="/w4w-application"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="column is-4"></div>
            </div>
          </section>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default WisdomStepTwoPage
